import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_fullscreen = _resolveComponent("fullscreen")!

  return (_openBlock(), _createBlock(_component_fullscreen, {
    modelValue: _ctx.fullscreen,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fullscreen) = $event)),
    background: "#fff",
    "fullscreen-class": "fullscreen",
    "exit-on-click-wrapper": _ctx.exitOnClickWrapper
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view, { onInitFullScreen: _ctx.initFullScreen }, null, 8, ["onInitFullScreen"])
    ]),
    _: 1
  }, 8, ["modelValue", "exit-on-click-wrapper"]))
}