
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue'
import IconBase from '@/components/IconBase.vue'
import 'vue3-carousel/dist/carousel.css'
import '@/assets/scss/_carousel.scss'
import '@/assets/scss/_bloc-number.scss'
import store from '@/store'
import moment from 'moment'
import { doesFileExist, parseDepannageInfoFile } from '@/models/depannageInfo'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'Home',
  emits: ['initFullScreen'],
  setup() {
    const { t, n } = useI18n()
    return {
      t,
      n,
      navItems: [
        {
          title: t('navitems.reflexes'),
          url: '/good-reflexes',
          icon: 'shield'
        },
        {
          title: t('navitems.assistance'),
          url: '/assistance-questions/faqs',
          icon: 'message-circle'
        },
        {
          title: t('navitems.reportings'),
          url: '/reportings',
          icon: 'film'
        },
        {
          title: t('navitems.help'),
          url: '/need-help',
          icon: 'heart'
        }
      ],
      modalSlides: [
        {
          title: t('modalslides.tuto1.title'),
          content: null,
          image: 'tuto-1.svg',
          alt: t('modalslides.tuto1.alt')
        },
        {
          title: t('modalslides.tuto2.title'),
          content: null,
          image: 'tuto-2.svg',
          alt: t('modalslides.tuto2.alt')
        },
        {
          title: t('modalslides.tuto3.title'),
          content: null,
          image: 'tuto-3.svg',
          alt: t('modalslides.tuto3.alt')
        },
        {
          title: t('modalslides.tuto4.title'),
          content: null,
          image: 'tuto-4.svg',
          alt: t('modalslides.tuto4.alt')
        }
        // ,
        // {
        //   title: t('modalslides.tuto5.title'),
        //   content: t('modalslides.tuto5.content'),
        //   image: 'tuto-5.svg',
        //   alt: t('modalslides.tuto5.alt')
        // },
        // {
        //   title: t('modalslides.tuto6.title'),
        //   content: t('modalslides.tuto6.content'),
        //   image: 'tuto-6.svg',
        //   alt: t('modalslides.tuto6.alt')
        // },
        // {
        //   title: t('modalslides.tuto7.title'),
        //   content: t('modalslides.tuto7.content'),
        //   image: 'tuto-7.svg',
        //   alt: t('modalslides.tuto7.alt')
        // },
        // {
        //   title: t('modalslides.tuto8.title'),
        //   content: t('modalslides.tuto8.content'),
        //   image: 'tuto-8.svg',
        //   alt: t('modalslides.tuto8.alt')
        // }
      ]
    }
  },
  data() {
    return {
      depannageInfo: store.state.depannageInfo,
      noDataMessage: store.state.noDataMessage,
      intervalRefresh: undefined as number | undefined
    }
  },
  methods: {
    async refreshDepannageInfos(): Promise<void> {
      if (
        store.state.depannageInfoFileName != null &&
        store.state.depannageInfoFileName !== ''
      ) {
        const response = doesFileExist(
          process.env.VUE_APP_FILE_FOLDER +
            store.state.depannageInfoFileName +
            '.txt'
        )
        if (response === 200) {
          const infos = await parseDepannageInfoFile(
            process.env.VUE_APP_FILE_FOLDER +
              store.state.depannageInfoFileName +
              '.txt'
          )
          store.commit('setDepannageInfo', infos)
          clearInterval(this.intervalRefresh)
          this.depannageInfo = infos
        }
      }
    },
    initFullScreen(navigate, event) {
      this.$emit('initFullScreen')
navigate(event)
    }
  },
  computed: {
    toHourString() {
      return (value: Date | undefined) => {
        if (value) {
          return moment(String(value)).format('HH:mm').replace(':', 'h')
        }
        return ''
      }
    },
    getPositionInHighway(): string | null {
      if (this.depannageInfo?.pointParticulier1) {
        return (
          this.depannageInfo?.pointParticulier1 +
          ' ' +
          this.depannageInfo?.pointParticulier2
        )
      } else if (this.depannageInfo?.airePeage) {
        return (
          this.depannageInfo?.libelleTypeAire +
          ' de ' +
          this.depannageInfo?.airePeage
        )
      }
      return null
    }
  },
  components: {
    Header,
    IconBase
  },
  mounted() {
    if (this.depannageInfo == null) {
      this.intervalRefresh = window.setInterval(
        this.refreshDepannageInfos,
        30000
      )
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalRefresh)
  }
})
