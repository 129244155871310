import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'
import { parseDepannageInfoFile, doesFileExist } from '@/models/depannageInfo'
import TagService from '../models/TagService'
import { PageInfo } from '../models/ATInternet'
const myTagService = new TagService()

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:fileName?',
    name: 'Home',
    component: Home,
    beforeEnter: async (to, from, next) => {
      if (to.params.fileName != null && to.params.fileName !== '') {
        store.commit('setFirstEntry', true)
        store.commit('setDepannageInfoFileName', to.params.fileName.toString())
        const response = doesFileExist(process.env.VUE_APP_FILE_FOLDER + to.params.fileName.toString() + '.txt')
        if (response === 200) {
          store.commit('setNoDataMessage', null)
          store.commit('setDepannageInfo', (await parseDepannageInfoFile(process.env.VUE_APP_FILE_FOLDER + to.params.fileName.toString() + '.txt')))
        } else if (response === 404) {
          store.commit('setNoDataMessage', 'demand.comingsoon')
        } else {
          store.commit('setNoDataMessage', 'demand.wait')
        }
        next()
      } else if (store.state.depannageInfo != null) {
        const response = doesFileExist(process.env.VUE_APP_FILE_FOLDER + store.state.depannageInfo.nomFichier)
        if (response === 200) {
          store.commit('setFirstEntry', false)
        } else {
          // store.commit('setDepannageInfoFileName', '')
          store.commit('setDepannageInfo', null)
          store.commit('setNoDataMessage', 'demand.wait')
        }
        next()
      } else {
        store.commit('setNoDataMessage', 'demand.wait')
        next()
      }
    },
    meta: {
      smartTag: 'Accueil'
    }
  },
  {
    path: '/assistance-questions',
    name: 'AssistanceQuestions',
    component: () => import('@/views/Assistance/AssistanceQuestions.vue'),
    meta: {
      smartTag: 'Bon a savoir'
    }
  },
  {
    path: '/assistance-questions/faqs',
    name: 'AssistanceFAQs',
    component: () => import('@/views/Assistance/FAQs.vue'),
    meta: {
      smartTag: 'FAQ'
    }
  },
  {
    path: '/good-reflexes',
    name: 'GoodReflexes',
    component: () => import('@/views/GoodReflexes/GoodReflexes.vue'),
    meta: {
      smartTag: 'Bons reflexes'
    }
  },
  {
    path: '/need-help',
    name: 'NeedHelp',
    component: () => import('@/views/NeedHelp/NeedHelp.vue'),
    meta: {
      smartTag: 'Besoin de soutien'
    }
  },
  {
    path: '/reportings',
    name: 'Reportings',
    component: () => import('@/views/Reportings/Reportings.vue'),
    meta: {
      smartTag: 'Reportages'
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/Error/Error.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  // Send page event to AT internet Tag composer
  if (to.meta.smartTag) {
    myTagService.pageSend({ name: to.meta.smartTag } as PageInfo)
  }
})

export default router
