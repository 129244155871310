<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       :width="width"
       :height="height"
       viewBox="0 0 18 18"
       :aria-labelledby="labelId"
       role="presentation"
  >
    <title :id="labelId" lang="fr">Icône {{iconLabel}}</title>
    <g :fill="iconColor">
      <component :is="iconName" />
    </g>
  </svg>
</template>

<script>
import ArrowLeft from './icons/ArrowLeft.vue'
import Close from './icons/Close.vue'
import Film from './icons/Film.vue'
import Heart from './icons/Heart.vue'
import MapLocation from './icons/MapLocation.vue'
import MessageCircle from './icons/MessageCircle.vue'
import PlayCircle from './icons/PlayCircle.vue'
import Shield from './icons/Shield.vue'

export default {
  name: 'IconBase',
  data() {
    return {
      labelId: this.iconLabel.split(/\s/).join('')
    }
  },
  props: {
    iconName: {
      type: String,
      required: true,
      default: 'Close'
    },
    iconLabel: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  },
  components: {
    ArrowLeft,
    Close,
    Film,
    Heart,
    MapLocation,
    MessageCircle,
    PlayCircle,
    Shield
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
}
</style>
