
import { defineComponent } from 'vue'
import IconBase from '@/components/IconBase.vue'

export default defineComponent({
  name: 'Header',
  setup() {
    return {}
  },
  props: {
    isHome: { type: Boolean, default: null },
    title: { type: String, default: 'Titre de la page' },
    icon: { type: String, default: null }
  },
  components: {
    IconBase
  }
})
