import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { createI18n } from 'vue-i18n'
import frMsg from '../public/locales/fr.json'
import enMsg from '../public/locales/en.json'
import VueFullscreen from 'vue-fullscreen'
import vueVimeoPlayer from 'vue-vimeo-player'

// Fichiers de ressources
// TODO EDT utiliser des constantes plutot que les variables d'environnement ? Comment faire pour que l'utilisateur puisse switcher anglais/fr ?
const vue18 = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'fr',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr', // TODO EDT toujours 'fr' car il y aura toujours toutes les ressources ?
  messages: {
    fr: frMsg,
    en: enMsg
  }
})

createApp(App)

  .use(VueFullscreen)
  .use(vueVimeoPlayer)
  .use(store)
  .use(router)
  .use(vue18)
  .mount('#app')
