
import 'vue3-carousel/dist/carousel.css'
import '@/assets/scss/_carousel.scss'
import '@/assets/scss/_bloc-number.scss'
import { defineComponent } from 'vue'
import { isMobile } from 'mobile-device-detect'
export default defineComponent({
  data() {
    return {
      intervalRefresh: undefined as number | undefined,
      fullscreen: false,
      exitOnClickWrapper: false
    }
  },
  methods: {
    initFullScreen() {
      this.fullscreen = isMobile
    }
  }
})
