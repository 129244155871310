import { DepannageInfo } from '@/models/depannageInfo'
import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    depannageInfo: null as DepannageInfo | null,
    firstEntry: true as boolean,
    noDataMessage: null as string | null,
    depannageInfoFileName: null as string | null
  },
  mutations: {
    setDepannageInfo(state, info) {
      state.depannageInfo = info
    },
    setFirstEntry(state, val) {
      state.firstEntry = val
    },
    setNoDataMessage(state, msg) {
      state.noDataMessage = msg
    },
    setDepannageInfoFileName(state, name) {
      state.depannageInfoFileName = name
    }
  },
  plugins: [createPersistedState()],
  actions: {
  },
  modules: {
  },
  getters: {
  }
})
