import { PageInfo, ClickInfo } from './ATInternet'

declare const ATInternet: any
export type Tag = any

 // Service that send data to AT internet (only in production)
export default class TagService {
  private tag: Tag

  constructor() {
    this.tag = new ATInternet.Tracker.Tag()
  }

  click(info: ClickInfo): boolean {
    if (process.env.NODE_ENV === 'production') {
      return this.tag.click.send(info)
     }
    return false
  }

  clickListener(info: ClickInfo): void {
    if (process.env.NODE_ENV === 'production') {
      this.tag.clickListener.send(info)
    }
  }

  pageSend(info: PageInfo): void {
    if (process.env.NODE_ENV === 'production') {
      this.tag.page.send(info)
    }
  }
}
