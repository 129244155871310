export interface DepannageInfo {
    numeroFiche: number,
    nomFichier: string,
    dateHeureFichier: Date,
    nomSmsDep: string,
    typeVehicule: string,
    autoroute: string,
    prMetre: number,
    sens: string,
    airePeage: string | undefined,
    typeAire: string | undefined,
    libelleTypeAire: string | undefined,
    pointParticulier1: string | undefined,
    pointParticulier2: string | undefined,
    latitude: number,
    longitude: number,
    dateHeurePriseEnCharge: Date,
    dateHeureArriveeEstimee: Date
}

export async function parseDepannageInfoFile(path: string): Promise<DepannageInfo> {
    const depannageInfo: Record<string, string> = {}

    const file = await fetch(path)
    const buffer = await file.arrayBuffer()
    const blob = new Blob([buffer], { type: 'text/plain; charset=utf-8' })

    const val = await blob.text()
    const props: string[] = val.split('\n').join('\r\n').split('\r\n').filter(str => str.includes('=') && !([' ', '-', '?'].includes(str.split('=')[1])))
    props.forEach(prop => {
        const splitted = prop.split('=')
        depannageInfo[splitted[0]] = splitted[1]
    })

    return parseDepannageInfo(depannageInfo)
}

export function doesFileExist(urlToFile: string): number {
    const xhr = new XMLHttpRequest()
    xhr.open('HEAD', urlToFile, false)
    xhr.send()

    return xhr.status
}

function parseDepannageInfo(toParse: Record<string, string>): DepannageInfo {
    const toReturn: any = {}
    for (const rec in toParse) {
        const rec1ToLo = rec.charAt(0).toLowerCase() + rec.slice(1)
        toReturn[rec1ToLo] = fileDateTextToDate(toParse[rec]) ?? (isNaN(Number(toParse[rec])) ? toParse[rec] : Number(toParse[rec]))
    }
    return toReturn
}

function fileDateTextToDate(txt: string): Date | null {
    const splitted = txt.replace(' ', '/').replace(':', '/').split('/')
    if (splitted.length === 5) {
        const day = parseInt(splitted[0])
        const month = parseInt(splitted[1])
        const year = parseInt(splitted[2].length === 2 ? '20' + splitted[2] : splitted[2])
        const hour = parseInt(splitted[3])
        const min = parseInt(splitted[4])
        return new Date(year, month - 1, day, hour, min)
    } else return null
}
